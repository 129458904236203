.center {
  position: fixed; /* Keeps the component fixed over other elements */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw; /* Ensure it covers the full viewport width */
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(204, 204, 205, 0.3); /* Adjust opacity as needed */
  z-index: 9999; /* High z-index to ensure it's on top */
}

.wave {
  width: 5px;
  height: 100px;
  background: rgba(12, 74, 110, 0.3); /* sky-900 with 30% opacity */  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
