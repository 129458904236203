.image-container {
    /* Other styles */
    transition: box-shadow 0.3s, border 0.3s;
    border-radius: 50%;
  }
  
  .image-container:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border: 2px solid var(--primary); /* Use your primary color variable */
  }
  

  .hover-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    cursor: pointer;
    transition: opacity 0.3s;
  }
  
  .image-container:hover .hover-icon,
  .hover-icon:hover ~ .avatar {
    opacity: 1;
  }
  