body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.header-input::placeholder {
  color: white; /* Replace 'gray' with the color of your choice */
}

select {
  -webkit-appearance: none; /* Remove default arrow for Webkit browsers */
  -moz-appearance: none; /* Remove default arrow for Mozilla browsers */
  appearance: none; /* Remove default arrow for all browsers */
  background-image: url("./Assets/Images/chevron2.svg"); /* Path to your custom icon */
  background-repeat: no-repeat;
  background-position: right 10px center; /* Adjust the position as needed */
  background-color: transparent;
  outline: none;
  padding-right: 30px; /* Adjust padding to prevent text overlap with the icon */
  /* Add other styling as needed */
}
.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinners {
  -moz-appearance: textfield;
}

/* Hide spinners in Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spinners in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.amiri-regular {
  font-family: "Amiri", serif;
  font-weight: 400;
  font-style: normal;
}

.amiri-bold {
  font-family: "Amiri", serif;
  font-weight: 700;
  font-style: normal;
}

.amiri-regular-italic {
  font-family: "Amiri", serif;
  font-weight: 400;
  font-style: italic;
}

.amiri-bold-italic {
  font-family: "Amiri", serif;
  font-weight: 700;
  font-style: italic;
}
